import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import AppSuper from './AppSuper';
import AppVisitor from './AppVisitor';
import AppStudent from './AppVisitor';
import AppQuotes from './AppQuotes';
import AppPlanSubmit from './AppPlanSubmit';
import AppOrder from './AppOrder';
import * as serviceWorker from './serviceWorker';

// Default App
if (document.getElementById('app')) {
  ReactDOM.render(<App />, document.getElementById('app'));
}

// Super
const elFormSuper = document.getElementById('frmSuper');
// elFormSuper.dataset returns DOMStringMap object

if (elFormSuper) {
  ReactDOM.render(
    <AppSuper insuranceType="super" {...{ ...elFormSuper.dataset }} />,
    elFormSuper
  );
}

// Visitor
const elFormVisitor = document.getElementById('frmVisitor');
if (elFormVisitor) {
  ReactDOM.render(
    <AppVisitor insuranceType="visitor" {...{ ...elFormVisitor.dataset }} />,
    elFormVisitor
  );
}

// Student
const elFormStudent = document.getElementById('frmStudent');
if (elFormStudent) {
  ReactDOM.render(
    <AppStudent insuranceType="student" {...{ ...elFormStudent.dataset }} />,
    elFormStudent
  );
}

// Quotes
const elResultQuotes = document.getElementById('resultQuotes');
if (elResultQuotes) {
  const props = {
    insuranceType: elResultQuotes.dataset.insuranceType,
    formPath: elResultQuotes.dataset.formPath
  };

  if (props.insuranceType && props.formPath) {
    ReactDOM.render(
      <AppQuotes {...props} />,
      document.getElementById('resultQuotes')
    );
  } else {
    ReactDOM.render(
      <>Invalid params</>,
      document.getElementById('resultQuotes')
    );
  }
}

// Plan Submit
if (document.getElementById('planSubmit')) {
  ReactDOM.render(<AppPlanSubmit />, document.getElementById('planSubmit'));
}

// Order
if (document.getElementById('order')) {
  ReactDOM.render(<AppOrder />, document.getElementById('order'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
