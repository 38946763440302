import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Button, Typography } from '@material-ui/core';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import { PhoneFormat } from 'components';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: 'solid 1px #ccc',
    paddingTop: '1rem'
  },
  cta: {
    marginBottom: theme.spacing(1)
  }
}));

const Summary = ({
  client,
  insuranceType,
  coverageType,
  onRateSelect,
  query,
  record
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={2}
      className={clsx('quotes-item-summary', classes.root)}>
      <Grid item sm={4}>
        <Typography variant="h5" gutterBottom>
          Summary
        </Typography>
        {insuranceType === 'student' ? (
          <Typography variant="body2">${record.disclaimer}</Typography>
        ) : (
          <>
            <Typography variant="body2">
              Coverage: ${query.coverage1}
            </Typography>
            {coverageType === 'couple' && (
              <Typography variant="body2">
                Coverage (2): ${query.coverage2}
              </Typography>
            )}
          </>
        )}

        {insuranceType !== 'student' && (
          <Typography variant="body2">
            Deductible: {record.deductible}
          </Typography>
        )}
        <Typography variant="body2">
          Period: {query.days1}{' '}
          {query.days2 && query.days1 !== query.days2 ? `/${query.days2}` : ''}{' '}
          days
        </Typography>

        {insuranceType !== 'student' && (
          <>
            <Typography variant="body2">
              Pre-existing medical conditions:{' '}
              {query.havePreConditions1 ? 'Covered' : 'Not covered'}
            </Typography>
            {coverageType === 'couple' && (
              <Typography variant="body2">
                Pre-existing medical conditions (2):{' '}
                {query.havePreConditions2 ? 'Covered' : 'Not covered'}
              </Typography>
            )}
          </>
        )}
      </Grid>

      <Grid item sm={4}>
        <div
          className="quotes-item-summary"
          style={{ textAlign: 'left' }}
          dangerouslySetInnerHTML={{ __html: record.plan.summary }}
        />
      </Grid>

      <Grid item sm={4}>
        <Button
          variant="outlined"
          className={clsx('cta-client-phone', classes.cta)}
          fullWidth
          href={`tel:${client.cellNo}`}
          startIcon={<PhoneIphoneIcon />}>
          <PhoneFormat number={client.cellNo} />
        </Button>

        <Button
          className={clsx('quotes-item-details-buy-cta', classes.cta)}
          color="primary"
          disableElevation
          fullWidth
          onClick={() => {
            onRateSelect(record);
          }}
          variant="outlined">
          Benefit Summary
        </Button>

        {record.plan.buyDirectUrl ? (
          <Button
            className={clsx('quotes-item-details-buy-cta', classes.cta)}
            color="secondary"
            disableElevation
            fullWidth
            href={record.plan.buyDirectUrl}
            target="_blank"
            variant="contained">
            Buy Directly
          </Button>
        ) : (
          ''
        )}

        <Button
          className={clsx('quotes-item-details-buy-cta', classes.cta)}
          color="primary"
          disableElevation
          fullWidth
          onClick={() => {
            onRateSelect(record);
          }}
          variant="contained">
          Start Application
        </Button>
      </Grid>
    </Grid>
  );
};

export default Summary;
