import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
//import { CssBaseline } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import theme from './theme';
import { createBrowserHistory } from 'history';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Super,
  Visitor,
  SuperSingle,
  SuperCouple,
  VisitorSingle,
  VisitorCouple,
  VisitorFamily,
  Student,
  Quotes,
  PlanSubmit,
  Order,
  OrderConfirmation,
  NotFound
} from 'components';
import global from './theme/global';

//define global styles
const useStyles = makeStyles({
  '@global': global
});

export const history = createBrowserHistory();

function App() {
  useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={theme}>
        <ToastContainer />
        <Router history={history}>
          <Switch>
            <Route
              path="/super-visa-insurance/single/:slug/"
              render={(props) => <SuperSingle {...props} />}
            />
            <Route
              path="/super-visa-insurance/couple/:slug/"
              render={(props) => <SuperCouple {...props} />}
            />
            <Route
              path="/super-visa-insurance/quotes/:slug/"
              render={(props) => <Quotes insuranceType="super" {...props} />}
            />
            <Route
              exact
              path={[
                '/super-visa-insurance',
                '/super-visa-insurance/couple',
                '/super-visa-insurance/single'
              ]}
              render={(props) => <Super {...props} />}
            />
            <Route
              path="/super-visa-insurance/quotes"
              render={(props) => <Quotes insuranceType="super" {...props} />}
            />
            <Route
              path="/visitors-insurance/single/:slug/"
              render={(props) => <VisitorSingle {...props} />}
            />
            <Route
              path="/visitors-insurance/couple/:slug/"
              render={(props) => <VisitorCouple {...props} />}
            />
            <Route
              path="/visitors-insurance/family/:slug/"
              render={(props) => <VisitorFamily {...props} />}
            />
            <Route
              path="/visitors-insurance/quotes/:slug/"
              render={(props) => <Quotes insuranceType="visitor" {...props} />}
            />
            <Route
              exact
              path={[
                '/visitors-insurance',
                '/visitors-insurance/single',
                '/visitors-insurance/couple',
                '/visitors-insurance/family'
              ]}
              render={(props) => <Visitor {...props} />}
            />
            <Route
              exact
              path="/visitors-insurance/quotes"
              render={(props) => <Quotes insuranceType="visitor" {...props} />}
            />
            <Route
              exact
              path={[
                '/student-insurance',
                '/international-student-insurance-canada'
              ]}
              render={(props) => <Student {...props} />}
            />
            <Route
              exact
              path="/student-insurance/quotes"
              render={(props) => <Quotes insuranceType="student" {...props} />}
            />
            <Route
              exact
              path="/plan"
              render={(props) => <PlanSubmit {...props} />}
            />
            <Route
              component={OrderConfirmation}
              exact
              path="/order-confirmation"
            />
            <Route component={Order} exact path="/order" />} />
            {/*<Route
              exact
              path="/:planSlug"
              render={(props) => {
                const matches = props.match.params.planSlug.match(
                  /^([A-Za-z0-9-]*)-visitors-to-canada-insurance$/
                );
                if (matches && matches[1]) {
                  return <SuperSingle planSlug={matches[1]} {...props} />;
                } else {
                  return <NotFound />;
                }
              }}
            />
            } />*/}
            <Route component={NotFound} />
          </Switch>
        </Router>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
